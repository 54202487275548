<template>
    <div>
        {{JSON.stringify(locationToCheck)}}
        <GmapMap
                :center="locationToCheck"
                :zoom="15"
                ref="map"
                style="height: 200px"
                class="missionMap"
                :options="mapOptions"
        >
            <GmapMarker
                    :position="locationToCheck"
                    :clickable="false"
                    :draggable="false"
            />
            <GmapMarker
                    :position="destination"
                    :clickable="false"
                    v-if="destinationVisible"
                    :draggable="false"
                    :icon="require(`@/assets/flag.png`)"
            />
            <GmapMarker
                :key="'location'+index"
                v-for="(m, index) in locations"
                :position="{lat: parseFloat(m.Position_lat) ,lng: parseFloat(m.Position_lng)}"
                :clickable="true"
                v-if="m.status === 'ממתין'"
                :icon="'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'"
                :draggable="false"
                @click="openSubmissionPopup(m)"
            />
            <DirectionsRenderer
                :map="$refs.map"
                :travelMode="'DRIVING'"
                :origin="locationToCheck"
                :destination="destination"
                :waypoints="toWaypoints(locations)"
                :route="route"
                @route="emitRoute"
            />
        </GmapMap>
        <div v-if="checkIfAllTasksDone"></div>
    </div>
</template>

<script>
    import {gmapApi} from 'vue2-google-maps'
    import {mapState} from 'vuex';
    import DirectionsRenderer from "@/components/DirectionsRenderer";

    export default {
        name: "vMap",
        components: {DirectionsRenderer},
        props: ['missionPolygon', 'currentLocation','destination','destinationVisible','locations', 'route'],
        data() {
            return {
                google: gmapApi,
                locationToCheck: {lat:0,lng: 0},
                mapOptions: {
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    currentPolygon: null,
                },
                polygonOptions: {
                    paths: null,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.2
                }

            }
        },
        methods: {
            emitRoute: function (route) {
                this.$emit('route', route)
            },
            toWaypoints: locations => locations?.map(locationData => ({
                location: {
                    lat: parseFloat(locationData.Position_lat),
                    lng: parseFloat(locationData.Position_lng)
                },
                stopover: true
            })),
            checkIfLocationWithinPolygon: function () {
                let self = this;
                return new Promise(function (resolve){
                    self.$refs.map.$mapPromise.then((map) => {
                        self.locationToCheck = new google.maps.LatLng(self.currentLocation.lat, self.currentLocation.lng)
                        let result = google.maps.geometry.poly.containsLocation(self.locationToCheck, self.currentPolygon)
                        resolve(result)
                    })
                })

            },
            openSubmissionPopup: function(subMission){
                this.$emit('openSubmissionPopup', subMission)
            }
        },
        computed: {
            ...mapState(['activeMission']),
            checkIfAllTasksDone() {
                let self = this;
                setTimeout(function(){
                    if(self.destinationVisible) {
                        self.$refs.map.$mapPromise.then((map) => {
                            let locationToEnd = new google.maps.LatLng(self.destination.lat, self.destination.lng)
                            let cityCircle = new google.maps.Circle({
                                strokeColor: '#89bc41',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#89bc41',
                                fillOpacity: 0.35,
                                map: map,
                                center: locationToEnd,
                                radius: self.activeMission.missionDistanceFromDestination * 1000
                            });
                        })
                    }

                },500)

                return this.destinationVisible
            }

        },
        mounted() {
            let self = this;
            this.polygonOptions.paths = this.missionPolygon
            self.$refs.map.$mapPromise.then((map) => {
                self.currentPolygon = new google.maps.Polygon(this.polygonOptions);
                self.currentPolygon.setMap(map);
                // self.$emit('setCurrentPolygon', self.currentPolygon)
                self.checkIfLocationWithinPolygon()
            })
        }
    }
</script>

<style scoped lang="scss">
    .missionMap {
        margin-top: 70px;
    }
</style>
