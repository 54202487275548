<template>
    <div class="header">
        <v-app-bar
                min-height="'137px'"
                color="white"
                clipped-right
                height="70"
                fixed
        >
            <v-icon color="primary"
                    size="40"
                    @click="drawer = !drawer">menu
            </v-icon>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-img
                        alt="mai"
                        class="shrink"
                        contain
                        :src="logoSrc"
                        transition="scale-transition"
                        width="40"
                />
            </div>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                width="100%"
                clipped
                absolute
                height="'539px'">
            <v-sheet color="primary"
                     tile
                     class="white--text ps-12 userData" height="70">שלום, {{userName}}
            </v-sheet>
            <v-list class="px-4 py-0">
                <template v-for="(item,index) in items">
                    <v-divider v-if="item.divider" :key="index"></v-divider>
                    <v-list-item
                            v-else-if="item.routerLink"
                            class="ps-10 my-0 py-1"
                            color="primary"
                            :key="item.title"
                            tile
                            link
                            :to="item.url"
                    >
                        <v-list-item-icon class="ml-7">
                            <v-img width="28" :src="getImageLink(item.icon)"></v-img>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                    <v-list-item
                            v-else
                            class="ps-10 my-0 py-1"
                            color="primary"
                            :key="item.title"
                            tile
                            link
                            :href="item.url"
                    >
                        <v-list-item-icon class="ml-7">
                            <v-img width="28" :src="getImageLink(item.icon)"></v-img>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "vHeader",
        data: () => {
            return {
                drawer: false,
                group: null,
                logoSrc: require(`@/assets/logo.svg`),
                items: [
                    {title: 'ראשי', icon: 'home-icon', url: '/', routerLink: true},
                    {divider: true, inset: true},
                    {title: 'צור קשר', icon: 'call-icon', url: 'tel:0775001090', routerLink: false},
                    {divider: true, inset: true},
                    {title: 'התנתק', icon: 'sign-out-icon', url: '/logout', routerLink: true},
                ]
            }
        },
        methods: {
            getImageLink: function getImageLink(icon) {
                return require(`@/assets/${icon}.svg`)
            }
        },
        computed: {
            ...mapState(['userName'])
        },
        watch: {
            group() {
                this.drawer = false
            },
        },
    }
</script>

<style scoped lang="scss">
    .userData {
        font-size: 19px;
        font-weight: 500;
        text-align: right;
        color: #ffffff;
        display: flex;
        align-items: center;
    }

    aside {
        top: 70px !important;
    }
</style>
