<template>
    <div>

        <v-card
            class="mx-auto mission-card mb-5"
            :class="{'mission-done': mission.missionStatus === 'בוצע', 'mission-process': mission.missionStatus === 'בטיפול', 'mission-new': mission.missionStatus === 'ממתין'}"
            max-width="344"
            :min-height="212"
            :max-height="510"
            elevation="10"

        >
            <v-card-title class="mission-title black--text mb-1 py-0 px-5 mt-5 pe-12 breakWord">
                {{mission.missionTitle}}
            </v-card-title>
            <v-card-text v-if="mission.missionStatus !== 'בוצע'"
                         class="mission-data black--text px-5 mb-6 flex-row">
                <b>מתי:</b> {{mission.missionDate}}
                <v-sheet height="18"
                         width="47"
                         color="black"
                         align="center"
                         justify="center"
                         dark
                         tile
                         class="px-1 pb-1 ms-1"
                         v-if="isToday(mission.missionDate)">היום!
                </v-sheet>
                <br>
                {{mission.missionRangeStart}} - {{mission.missionRangeEnd}}
                <br>
                <b>קריאות שירות:</b> {{mission.missionNumberOfSubTasks}}
                <br>
                <b>מסלול:</b> {{mission.missionRoute}}
                <br>
                <b>יעד:</b> {{mission.missionDestination}}
                <br>
                <b>מחיר:</b> {{mission.missionValue}}
                <br>
                <b>תיאור: </b>
                <div class="missionDescription" :class="{'active' : IsShowDescription}">
                    {{mission.missionDescription}}
                </div>
                <span class="readMore"
                      @click="toggleDescription">עוד</span>
            </v-card-text>
            <v-card-text v-if="mission.missionStatus === 'בוצע'"
                         class="mission-data black--text px-5 mb-6 mt-5 flex-row">
                קוד המאי-קוין לאישור:
                <br>
                <span class="primary--text coinID mt-1">{{mission.missionCoinID}}</span>
                <br>
                ניתן להציג קוד זה עד לתאריך <b>{{mission.missionCoinValidDate}}</b>
            </v-card-text>
            <v-sheet :color="getMissionColor(mission.missionStatus)"
                     tile
                     :align="'center'"
                     :justify="'end'"
                     v-if="mission.missionStatus !== ''"
                     class="mission-data-status pt-4">
                <v-img width="17" :src="require(`@/assets/${getMissionIcon(mission.missionStatus)}.svg`)"></v-img>
                <p class="white--text mb-0">{{getMissionStatus(mission.missionStatus)}}</p>
            </v-sheet>

            <v-card-actions class="py-0 px-0 mt-3">
                <v-btn color="secondary"
                       block
                       absolute
                       class="white--text"
                       height="42"
                       :to="'mission/' + mission.missionID "
                       :disabled="mission.missionCoinID !== null"
                       tile
                       bottom>לפרטים נוספים
                    <v-icon>keyboard_arrow_left</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "vMissionCard",
        props: ['mission'],
        components: {},
        data: function () {
            return {
                IsShowDescription: false
            }
        },

        methods: {
            toggleDescription() {
                this.IsShowDescription = !this.IsShowDescription;
            },
            isToday: function (date) {
                return moment(date).isSame(new Date(), "day")
            },
            getMissionColor: function (status) {
                let color = '';
                switch (status) {
                    case 'ממתין':
                        color = 'new';
                        break;
                    case 'בוצע':
                        color = 'done';
                        break;
                    case 'בטיפול':
                        color = 'primary';
                        break;
                }
                return color

            },
            getMissionStatus: function (status) {
                let statusText = '';
                switch (status) {
                    case 'ממתין':
                        statusText = 'חדש';
                        break;
                    case 'בוצע':
                        statusText = 'בוצע';
                        break;
                    case 'בטיפול':
                        statusText = 'בביצוע';
                        break;
                }
                return statusText

            },
            getMissionIcon: function (status) {
                let icon = '';
                switch (status) {
                    case 'ממתין':
                        icon = 'new';
                        break;
                    case 'בוצע':
                        icon = 'done';
                        break;
                    case 'בטיפול':
                        icon = 'process';
                        break;
                }
                return icon

            }
        }
    }
</script>

<style scoped lang="scss">
    .mission-title {
        font-size: 16px;
        line-height: 25px;
        font-weight: bold;

        &.breakWord {
            word-break: break-word;
        }
    }

    .mission-data {
        font-size: 16px;
        line-height: 20px;
    }

    .v-card.mission-card.mx-auto {
        border-radius: 7px;
        overflow: hidden;

        &.mission-new {
            border: 2px solid $new_color;
        }
    }
    /* תיאור המשימה */
    /* קיים גם במסך הבית ובמסך משימה */
    .missionDescription {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 68%;
        line-height: 0.7;
        display: inline-block;

        &.active {
            white-space: normal;
            text-overflow: initial;
            overflow: visible;
            display: inline;
        }

    }

    .mission-data-status {
        position: absolute;
        left: -35px;
        top: -10px;
        width: 110px;
        height: 55px;
        transform: rotate(-45deg);

        .v-responsive.v-image {
            transform: rotate(45deg);
        }
    }

    .v-sheet--tile {
        display: inline-block;
    }

    .v-btn.v-btn--bottom.v-btn--router {
        bottom: 0;
        font-size: 16px;
        font-weight: 500;

        &.v-btn--disabled {
            color: black !important;

            .v-icon {
                color: black !important;
            }
        }
    }

    .coinID {
        font-size: 22.5px;
        font-weight: bold;
        display: inline-block;
    }
</style>
