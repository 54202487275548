<template>
    <div>
        <v-dialog
                width="375"
                v-model="showPopup"
                persistent
                max-width="375"
                :content-class="'cancelTaskModal'"
        >
            <v-card class="pt-9">

                <v-card-text class="text-center">
                    האם אתה בטוח שברצונך
                    <br>
                    לבטל את המשימה?
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text class="text-center font-weight-bold text--black">
                    {{missionData.missionTitle}}
                    <br>
                    שעת התחלה: {{missionData.missionRangeStart}}
                    <br>
                    שעת סיום: {{missionData.missionRangeEnd}}
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text class="text-center pb-0 font-weight-bold">
                    {{missionData.missionRoute}}
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="px-0 py-0 mt-5">
                    <v-btn
                            :width="'50%'"
                            height="54"
                            color="decline"
                            tile
                            elevation="0"
                            @click="notCancel()"
                    >
                        לא, התחרטתי
                    </v-btn>

                    <v-btn
                            :width="'50%'"
                            height="54"
                            color="secondary"
                            tile
                            elevation="0"
                            @click="approveCancel()"
                    >
                        כן, לבטל
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: ['showPopup', 'missionData'],
        name: "vCancelMissionPopup",
        methods: {
            notCancel: function () {
                this.$emit('notCancel')
            },
            approveCancel: function () {
                this.$emit('approveCancel')
            }
        }
    }
</script>

<style scoped lang="scss">
    .cancelTaskModal {
        .v-card__actions > .v-btn.v-btn + .v-btn {
            margin: 0;
        }

        .v-card__text {
            color: #000 !important;
            font-size: 19px;
            line-height: 1.21;
        }

        .v-card {
            border-radius: 7px;
            overflow: hidden;
        }

        .v-btn {
            font-size: 19px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0;
        }
    }
</style>
