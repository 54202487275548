<template>
    <div>
        <span style="display: none;">

        !missionOnProcessComputed : {{ !missionOnProcessComputed }} <br>
        !receivedCode : {{ !receivedCode }} <br>
        missionAllowStart : {{ missionAllowStart }} <br>
        !missionDone : {{ !missionDone }} <br>
        condition for display start misstion btn : {{
                !missionOnProcessComputed && !receivedCode && missionAllowStart && !missionDone
            }} <br>
           <br>
        this.missionOnProcess : {{ missionOnProcess }} <br>
        <span v-if="activeMission"> this.activeMission.missionStatus : {{ activeMission.missionStatus }} <br></span>
        !this.receivedCode : {{ !receivedCode }} <br>
        condition for cumputed missionOnProcessComputed : {{
                !missionOnProcessComputed && !receivedCode && missionAllowStart && !missionDone
            }} <br>
        </span>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="loaderSection" v-if="IsLoading">
            <v-skeleton-loader
                min-height="150" :transition="transition" type="image">
            </v-skeleton-loader>
            <p class="text-center grey-lighten-3 py-5">העמוד בטעינה...</p>
            <v-skeleton-loader :transition="transition" type="list-item-avatar@4">
            </v-skeleton-loader>
            <v-skeleton-loader :transition="transition" type="article">
            </v-skeleton-loader>
            <v-skeleton-loader :transition="transition" type="card">
            </v-skeleton-loader>
        </div>


        <div v-else>
            <v-map ref="mapComponent"
                   :missionPolygon="activeMission.missionPolygon"
                   :current-location="currentLocation"
                   :locations="activeMission.subTasksDetails"
                   :destination="activeMission.missionDestinationCoords"
                   :destinationVisible="isAllTasksDone"
                   :route="activeMission.route"
                   @setCurrentPolygon="setCurrentPolygon"
                   @openSubmissionPopup="openSubmissionPopupFunc"
                   @route="updateRoute"
            ></v-map>
            <v-sheet min-height="45" class="headerBorder" :class="{'noBorder': receivedCode}"><h1>
                {{ activeMission.missionTitle }}</h1></v-sheet>
            <v-sheet color="primary" height="235" tile class="pt-4 d-flex flex-column justify-start align-center"
                     v-if="receivedCode">
                <p class="font-weight-bold white--text text-center mb-0">כל הכבוד! סיימת את המשימה</p>
                <v-img class="doneMission mb-2" width="65" height="65" max-width="65" max-height="65"
                       :src="require(`@/assets/well-done-logo.svg`)"></v-img>
                <p class="white--text text-center mb-1">קוד המאי-קוין שלך:</p>
                <p class="black--text font-weight-bold text-center mb-2 missionCoinID">
                    {{ activeMission.missionCoinID }}</p>
                <div class="validationTime" v-if="(coinRemainingTime.hours() + (coinRemainingTime.days() * 24)) < 99">
                    <p class="white--text text-center mb-0 me-2">תוקף הקופון יפוג בעוד:</p>
                    <div class="timeLeft">
                        <div class="secondsWrapper">
                                <span class="numbersWrapper"
                                      :style="{'transform': 'translate(7px, -' + coinRemainingTime.seconds()*33 + 'px)'}">
                                <span class="secondsData" v-for="index in 60" :key="index+'seconds'">
                                    {{ index - 1 }}<span v-if="index-1 < 10">0</span>
                                </span>
                                </span>
                        </div>
                        <span class="dotsWrapper"><span class="singleDot"></span><span class="singleDot"></span></span>
                        <div class="minutesWrapper">
                                <span class="numbersWrapper"
                                      :style="{'transform': 'translate(7px, -' + coinRemainingTime.minutes() *33 + 'px)'}">
                                <span class="minutesData" v-for="index in 60" :key="index+'minutes'">
                                    {{ index - 1 }}<span v-if="index-1 < 10">0</span>
                                </span>
                                </span>
                        </div>
                        <span class="dotsWrapper"><span class="singleDot"></span><span class="singleDot"></span></span>
                        <div class="hoursWrapper">
                                <span class="numbersWrapper"
                                      :style="{'transform': 'translate(7px, -' + (coinRemainingTime.hours() + (coinRemainingTime.days() * 24)) * 33 + 'px)'}">
                                <span class="minutesData" v-for="index in 100" :key="index+'hours'">
                                    {{ index - 1 }}<span v-if="index-1 < 10">0</span>
                                </span>
                                </span>
                        </div>
                    </div>
                </div>
            </v-sheet>
            <v-container>
                <v-row>
                    <v-col cols="12" class="py-0 px-5 missionText">
                        <div class="circleTimeline"></div>
                        <b>מאיפה לאסוף: </b> {{ activeMission.missionTitle }}
                    </v-col>
                    <v-col cols="12" class="py-0 px-5 missionText">
                        <div class="circleTimeline"></div>
                        <b>מתי: </b> יום {{ getDay(activeMission.missionDate) }} <span class="textBorder"></span>
                        {{ activeMission.missionDate }} <span class="textBorder"></span>
                        {{ activeMission.missionRangeStart }} עד
                        {{ activeMission.missionRangeEnd }}
                    </v-col>
                    <v-col cols="12" class="py-0 px-5 missionText">
                        <div class="circleTimeline"></div>
                        <b>מסלול: </b> {{ activeMission.missionRoute }}
                    </v-col>
                    <v-col cols="12" class="py-0 px-5 missionText">
                        <!--                        <v-img width="21" :src="require(`@/assets/place.svg`)"></v-img>-->
                        <div class="circleTimeline"></div>
                        <b>מתקן יעד: </b> {{ activeMission.missionDestination }}
                    </v-col>
                    <v-col cols="12" class="py-0 px-5">
                        <v-img width="21" :src="require(`@/assets/place.svg`)"></v-img>
                        <b>תיאור: </b>
                        <div class="missionDescription" :class="{active : IsShowDescription}">
                            {{ activeMission.missionDescription }}
                        </div>
                        <span class="readMore"
                              @click="toggleDescription()">עוד</span>
                    </v-col>
                </v-row>
                <v-row
                    v-if="(activeMission && activeMission.missionStatus ==  'בטיפול') || (activeMission && activeMission.missionStatus == 'בוצע'  && !receivedCode)"
                    class="tasksWrapper pb-0">
                    <v-col cols="12" class="pb-0" v-if="duration > 0">
                        <v-image-card
                            v-for="index in Math.ceil(duration/activeMission.missionHoursRange)"
                            :key="index + 'imageCard'"
                            :index="index"
                            :ref="'imageCard' + index"
                            :receivedCode="receivedCode"
                            :savedImage="activeMission.missionPhotos !== null ? activeMission.missionPhotos[index - 1]:''"
                            :startHour="getImageStartRange(index)"
                            :endHour="getImageEndRange(index)"
                            @outsidePolygon="outsidePolygon"
                            @saveImageToMission="saveImageToMission"
                        ></v-image-card>
                    </v-col>
                    <v-col v-if="isAllTasksDone" cols="12"
                           class="pb-0 mt-5">
                        <p class="mb-8 text-center font-weight-bold">
                            כל הכבוד! סיימת את המשימה
                            <br>
                            אתה יכול למסור את הפסולת למתקן טיפול:
                            <br>
                            {{ activeMission.missionDestination }}
                            <br>
                            עד לתאריך: {{ formatDate(activeMission.missionCoinValidDate) }}
                        </p>
                    </v-col>
                </v-row>
                <v-row class="mb-12" v-if="activeMission.subTasksDetails && activeMission.subTasksDetails.length">
                    <v-col class="mb-12">
                        <v-expansion-panels multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header>נקודות לאיסוף :
                                    {{ activeMission.subTasksDetails.length }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list two-line>
                                        <template v-for="(mission, index) in orderedSubTasksDetails">
                                            <v-list-item
                                                :key="'subMission-' + index"
                                                :disabled="mission.status === 'בוצע' || mission.status === 'בוטל' || !((activeMission && activeMission.missionStatus ==  'בטיפול') || (activeMission && activeMission.missionStatus == 'בוצע'  && !receivedCode))"
                                                @click="openSubmissionPopupFunc(mission)">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ mission.address }} {{ mission.houseNumber }},
                                                        {{ mission.city }}
                                                    </v-list-item-title>

                                                    <v-list-item-subtitle>סטטוס:
                                                        {{ mission.status }}
                                                        <span v-if="!!mission.distanceFromSubTaskToCurrntLocation">
                                                        <br>
                                                        מרחק :
                                                        {{mission.distanceFromSubTaskToCurrntLocation}}
                                                        </span>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-btn icon>
                                                        <v-icon size="40" color="grey lighten-1">mdi-chevron-left
                                                        </v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider v-if="index < activeMission.subTasksDetails.length - 1"
                                                       :key="index"></v-divider>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog v-model="subMissionPopup" max-width="700" ref="dialog">
                <v-sub-mission-popup
                    :data-to-use="activeSubMission"
                    ref="vpopup"
                    @closeModal="subMissionPopup = false; activeSubMission = null"
                ></v-sub-mission-popup>
            </v-dialog>
            <v-start-mission-popup
                :show-popup="startPopupVisible"
                :mission-data="activeMission"
                @startMission="startMission()"
                @cancelMission="cancelMission()"
            ></v-start-mission-popup>
            <v-cancel-mission-popup
                :show-popup="cancelPopupVisible"
                :mission-data="activeMission"
                @approveCancel="approveCancel()"
                @notCancel="notCancel()"
            ></v-cancel-mission-popup>
            <v-error-popup
                :show-popup="errorPopupVisible"
                :error-message="errorMessage"
                :need-to-reload="popupReturnReload"
                @hideErrorPopup="hideErrorPopup()"
            ></v-error-popup>
            <v-btn
                class="startTask"
                color="error"
                bottom
                fixed
                :width="'50%'"
                v-if="!receivedCode"
                tile
                right
                large
                height="60"
                @click="showCancelPopup"
            >
                ביטול משימה
            </v-btn>
            <v-btn
                class="startTask"
                color="secondary"
                bottom
                fixed
                :width="'50%'"
                tile
                left
                large
                height="60"
                v-if="(activeMission && activeMission.missionStatus === 'ממתין') && !receivedCode && missionAllowStart && !missionDone"
                @click="showStartPopup"
            >
                התחלת משימה
            </v-btn>
            <v-btn
                class="startTask mt-4"
                color="secondary"
                bottom
                :width="'50%'"
                tile
                fixed
                left
                large
                height="60"
                v-else-if="!receivedCode && (missionOnProcessComputed || missionDone)"
                :disabled="!isAllTasksDone"
                @click="getCoinsCode()"
            >
                לקבלת הקוד
            </v-btn>
            <v-btn
                class="startTask mt-4"
                color="secondary"
                bottom
                :width="'50%'"
                tile
                left
                fixed
                large
                height="60"
                v-else-if="!receivedCode && !missionOnProcessComputed && !missionDone"
                :disabled="true"
            >
                המשימה תיפתח<br>לביצוע ב- {{ formatDate(activeMission.missionDate) }}
            </v-btn>
        </div>
    </div>


</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import vMap from '../components/vMap'
import vStartMissionPopup from '../components/vStartMissionPopup'
import vCancelMissionPopup from '../components/vCancelMissionPopup'
import vErrorPopup from '../components/vErrorPopup'
import vImageCard from '../components/vImageCard'
import moment from 'moment'
import {gmapApi, } from 'vue2-google-maps'
import VSubMissionPopup from "@/components/vSubMissionPopup";

export default {
    name: "vMissionPage",
    components: {
        VSubMissionPopup,
        vMap,
        vStartMissionPopup,
        vCancelMissionPopup,
        vErrorPopup,
        vImageCard
    },
    computed: {
        ...mapState(['days', 'activeMission', 'duration', 'receivedCode', 'overlay']),
        isAllTasksDone() {
            return this.allMissionImages && this.duration
                && this.allMissionImages.length && this.allMissionImages.length === Math.ceil(this.duration / this.activeMission.missionHoursRange)
                && this.allMissionImages.every(item => !!item);
        },
        missionOnProcessComputed() {
            //return this.missionOnProcess || (this.activeMission && this.activeMission.missionStatus === 'בטיפול') || !this.receivedCode;
            if (!!this.activeMission === true) {
                return this.missionOnProcess || (this.activeMission && this.activeMission.missionStatus === 'בטיפול') || !this.receivedCode;
            }
            return false;
        },
        missionAllowStart() {
            //if no active mission received from server sent false as default
            if (!!this.activeMission === true) {
                let isMissionInFuture = moment(this.now, 'YYYY-MM-DDTHH:mm:ss.788Z').isBefore(this.activeMission.missionDate);

                let res = this.activeMission && this.activeMission.missionStatus === 'ממתין' && !isMissionInFuture;
                return res;

            } else {
                return false;
            }

        },
        missionDone() {
            return this.activeMission && this.activeMission.missionStatus === 'בוצע';
        },
        coinRemainingTime() {
            if (this.activeMission.missionCoinID) {
                let endDate = moment(this.activeMission.missionCoinValidDate).add(23, 'hours').add(55, 'minutes');
                let duration = moment.duration(endDate.diff(moment(this.now, 'YYYY-MM-DDTHH:mm:ss.788Z')));
                return duration;
            }
            return moment.duration(NaN);
        },
        orderedSubTasksDetails(){
            return this.activeMission.subTasksDetails.sort((a, b) => a.order > b.order);
        }
    },
    data() {
        return {
            subMissionPopup: false,
            activeSubMission: null,
            transition: 'scale-transition',
            google: gmapApi,
            startPopupVisible: false,
            cancelPopupVisible: false,
            missionOnProcess: false,
            now: '',
            currentLocation: {
                lat: null,
                lng: null,
            },
            distanceFromDestination: 0,
            errorPopupVisible: false,
            errorMessage: '',
            popupReturnReload: false,
            allMissionImages: [],
            locationWatcher: true,
            currentPolygon: null,
            distanceFromLastImage: 0,
            IsLoading: true,
            IsShowDescription: false,
            watchPositionID: null,
        };
    },
    methods: {
        ...mapMutations(['HIDE_LOADER', 'SHOW_LOADER', 'SET_MISSION_AS_ACTIVE', 'CANCEL_MISSION', 'REMOVE_LAST_IMAGE']),
        ...mapActions(['GET_TOKEN_CODE', 'GET_TASK_DATA', 'SAVE_IMAGE', 'GET_TIME', 'SAVE_ROUTE']),
        updateRoute: function (route) {
            if (this.activeMission.subTasksDetails)
                Object
                    .keys(this.activeMission.subTasksDetails)
                    .forEach(key => this.activeMission.subTasksDetails[key].order = route.routes[0].waypoint_order[key])
            this.SAVE_ROUTE({id: this.activeMission.missionId, route})
        },
        getDay: function (date) {
            return this.days[moment(date).day()]
        },
        showStartPopup: function () {
            this.startPopupVisible = true;
        },
        showCancelPopup: function () {
            this.cancelPopupVisible = true;
        },
        getTime: function () {
            let self = this;
            return self.GET_TIME().then(function (response) {
                self.now = response;
            })
        },
        cancelMission: function () {
            this.startPopupVisible = false;
        },
        notCancel: function () {
            this.cancelPopupVisible = false;
        },
        approveCancel: function () {
            this.startPopupVisible = false;
            let data = {
                lat: this.currentLocation.lat,
                lng: this.currentLocation.lng,
                id: this.activeMission.missionID
            }
            this.CANCEL_MISSION(data)
            this.missionOnProcess = false;
            if (window.localStorage.getItem('watchPositionActive' + this.$route.params.id) == 1) {
                clearInterval(this.watchPositionInterval)
                window.localStorage.דקאItem('watchPositionActive' + this.$route.params.id, "0");
            }
        },
        startMission: function () {
            let self = this;
            this.startPopupVisible = false;
            let data = {
                lat: this.currentLocation.lat,
                lng: this.currentLocation.lng,
                id: this.activeMission.missionID
            }
            let updatedTaskObj = this.SET_MISSION_AS_ACTIVE(data);
            //update the local Task object with the new status
            this.startWatchPosition()
            this.activeMission.missionStatus = 'בטיפול';
            this.missionOnProcess = true;

        },
        startWatchPosition: function () {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            let self = this;
            let missionId = window.location.pathname.split('/')[3];
            self.watchPositionInterval = setInterval(function () {
                if (self.now != undefined && self.activeMission != undefined) {
                    if (self.activeMission != undefined && self.activeMission.trackingEnabled == true) {
                        window.localStorage.setItem('watchPositionActive' + missionId, "1");
                    } else {
                        window.localStorage.setItem('watchPositionActive' + missionId, "0");
                    }
                    let isMissionEnded = moment(self.now, 'YYYY-MM-DDTHH:mm:ss.788Z').isAfter(self.activeMission.missionDate + "T" + self.activeMission.missionRangeEnd);
                    if (isMissionEnded && window.localStorage.getItem('watchPositionActive' + missionId) == "1") {
                        clearInterval(self.watchPositionInterval);
                        window.localStorage.setItem('watchPositionActive' + missionId, "0");
                    }
                }
                if (window.localStorage.getItem('watchPositionActive' + missionId) == "1") {
                    navigator.geolocation.getCurrentPosition(self.successWatchPosition, self.errorWatchPosition, options);
                }
            }, 5000)
        },
        successWatchPosition: function (pos) {
            let savedLocations;
            let missionId = window.location.pathname.split('/')[3];
            if (window.localStorage.getItem('locations_' + missionId) !== null) {
                savedLocations = JSON.parse(window.localStorage.getItem('locations_' + missionId))
            } else {
                savedLocations = [];
            }
            let crd = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
            }
            savedLocations.push(crd);
            window.localStorage.setItem('locations_' + missionId, JSON.stringify(savedLocations));
        },
        errorWatchPosition: function (error) {

        },
        getImageStartRange: function (index) {
            let start = moment(this.activeMission.missionRangeStart, 'HH:mm');
            return moment(start).add(this.activeMission.missionHoursRange * (index - 1), 'hours').format('HH:mm')
        },
        getImageEndRange: function (index) {
            let start = moment(this.activeMission.missionRangeStart, 'HH:mm');
            let end = moment(this.activeMission.missionRangeEnd, 'HH:mm');
            let startAfterAdd = moment(start, 'HH:mm').add((this.activeMission.missionHoursRange * (index - 1) + this.activeMission.missionHoursRange), 'hours');
            if (moment(startAfterAdd, 'HH:mm').isBefore(moment(end, 'HH:mm'))) {
                return moment(start).add((this.activeMission.missionHoursRange * (index - 1) + this.activeMission.missionHoursRange), 'hours').format('HH:mm')
            } else {
                return moment(end).format('HH:mm')
            }

        },
        isUserNearDestination: function () {
            let self = this
            return new Promise(function (resolve) {
                let lat1 = self.currentLocation.lat;
                let lng1 = self.currentLocation.lng;
                let lat2 = self.activeMission.missionDestinationCoords.lat;
                let lng2 = self.activeMission.missionDestinationCoords.lng;
                let R = 6371; // Radius of the earth in km
                let dLat = self.deg2rad(lat2 - lat1);  // deg2rad below
                let dLon = self.deg2rad(lng2 - lng1);
                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(self.deg2rad(lat1)) * Math.cos(self.deg2rad(lat2)) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2)
                ;
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                let d = R * c; // Distance in km
                self.distanceFromDestination = d;
                resolve(self.distanceFromDestination);
            });
        },
        calculateDistancFromSiteOrder: function () {
            let self = this
            return new Promise(function (resolve) {
                let lat1 = self.currentLocation.lat;
                let lng1 = self.currentLocation.lng;
                if (!self.activeMission.subTasksDetails) resolve(null)
                for(let subTask of self.activeMission.subTasksDetails){
                    let lat2 = subTask.Position_lat;
                    let lng2 = subTask.Position_lng;
                    let R = 6371; // Radius of the earth in km
                    let dLat = self.deg2rad(lat2 - lat1);  // deg2rad below
                    let dLon = self.deg2rad(lng2 - lng1);
                    let a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(self.deg2rad(lat1)) * Math.cos(self.deg2rad(lat2)) *
                        Math.sin(dLon / 2) * Math.sin(dLon / 2)
                    ;
                    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    let d = R * c; // Distance in km
                    subTask.distanceFromSubTaskToCurrntLocation = parseFloat(d).toFixed(2);

                }
                resolve(self.calculateDistancFromSiteOrder);
            });
        },
        showLocation: function (position) {

        },
        errorHandler: function (err) {
            if (err.code == 1) {
                alert("Error: Access is denied!");
            } else if (err.code == 2) {
                alert("Error: Position is unavailable!");
            }
        },
        deg2rad: function (deg) {
            return deg * (Math.PI / 180)
        },
        getCoinsCode: function () {
            let self = this;
            this.isUserNearDestination().then(function () {
                if (!self.isAllTasksDone) {
                    self.errorMessage = 'ניתן לסיים את המשימה <br> רק לאחר איסוף כל התמונות <br> והנתונים הגיאוגרפים';
                    self.errorPopupVisible = true;
                    self.popupReturnReload = false;
                } else if (self.distanceFromDestination > self.activeMission.missionDistanceFromDestination) {
                    self.errorMessage = 'ניתן לסיים את המשימה <br> רק בסמוך ל' + self.activeMission.missionDestination;
                    self.errorPopupVisible = true;
                    self.popupReturnReload = false;
                } else {
                    if (window.localStorage.getItem('watchPositionActive' + self.$route.params.id) == 1) {
                        clearInterval(self.watchPositionInterval)
                        window.localStorage.setItem('watchPositionActive' + self.$route.params.id, "0")
                    }
                    let path
                    if (self.activeMission && self.activeMission.trackingEnabled !== "סגור") {
                        let points = window.localStorage.getItem('locations_' + self.$route.params.id) ? JSON.parse(window.localStorage.getItem('locations_' + self.$route.params.id)) : [];
                        let parsedCoordinates = [];
                        for (let i = 0; i < points.length; i++) {
                            parsedCoordinates.push(new google.maps.LatLng(points[i]))
                        }
                        path = google.maps.geometry.encoding.encodePath(parsedCoordinates);
                    } else {
                        path = '';
                    }
                    let data = {
                        lat: self.currentLocation.lat.toString(),
                        lng: self.currentLocation.lng.toString(),
                        id: self.activeMission.missionID,
                        device: navigator.platform,
                        connectionType: navigator && navigator.connection && navigator.connection.effectiveType ? navigator.connection.effectiveType : 'לא זמין',
                        browser: navigator.vendor,
                        browserVersion: navigator.productSub,
                        path: path
                    };
                    self.GET_TOKEN_CODE(data).then(function (response) {
                        console.log(response);
                        // window.localStorage.removeItem('locations_' + self.$route.params.id);
                    }).catch(function (error) {
                        console.log(error);
                        self.errorMessage = "שגיאה(101) - תהליך העדכון נכשל , אנא פנה לתמיכה .";
                        self.errorPopupVisible = true;
                        self.popupReturnReload = true;
                    })
                }
            })
        },
        isUploadImageMinimumDistanceValid: function (from, to, self) {
            return new Promise(function (resolve) {
                //if the to onj is null it means the first image so return the define distance in mission object for validation to resolve successfully
                if (to == null) {
                    self.distanceFromLastImage = self.activeMission.missionMinimumDistanceFromImages;
                    let response = {
                        'isValid': true,
                        'missionMinimumDistanceFromImages': self.activeMission.missionMinimumDistanceFromImages,
                        'distanceFromLastImage': self.distanceFromLastImage
                    };
                    resolve(response);
                } else {

                    let R = 6371; // Radius of the earth in km
                    let dLat = self.deg2rad(from.lat - to.lat);  // deg2rad below
                    let dLon = self.deg2rad(from.lng - to.lng);
                    let a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(self.deg2rad(to.lat)) * Math.cos(self.deg2rad(from.lat)) *
                        Math.sin(dLon / 2) * Math.sin(dLon / 2)
                    ;
                    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    let d = R * c; // Distance in km
                    self.distanceFromLastImage = d;
                    let response = {
                        'isValid': self.distanceFromLastImage >= self.activeMission.missionMinimumDistanceFromImages,
                        'missionMinimumDistanceFromImages': self.activeMission.missionMinimumDistanceFromImages,
                        'distanceFromLastImage': d
                    };
                    resolve(response);
                }
            });
        },
        getLastImageLocation: function (currentIndex) {
            let self = this;
            console.log("getLastImageLocation() currentIndex => ", currentIndex);
            console.log("getLastImageLocation() activeMission => ", self.activeMission);
            let lastImageLocation = null;
            if (currentIndex > 0 && self.activeMission && self.activeMission.missionPhotos && self.activeMission.missionPhotos.length) {
                //MPA_0_505105000085296184_32.7850001_35.0059993_2020-05-21_16:22:56.png
                let latitude = self.activeMission.missionPhotos[currentIndex - 1].name.split('_')[3];
                let longtitue = self.activeMission.missionPhotos[currentIndex - 1].name.split('_')[4];
                lastImageLocation = {'lat': '', 'lng': ''};
                lastImageLocation.lat = latitude;
                lastImageLocation.lng = longtitue;
            }
            return lastImageLocation;
        },
        saveImageToMission: function (data) {

            let self = this;
            if (this.currentLocation.lat && this.currentLocation.lat) {
                data.lat = this.currentLocation.lat.toString();
                data.lng = this.currentLocation.lng.toString();
            } else {
                data.lat = '';
                data.lng = '';
            }

            data.missionID = self.activeMission.missionID;


            if (!data.savedImage) {
                this.$refs.mapComponent.checkIfLocationWithinPolygon().then(function (withinPolygonResponse) {
                    console.log("withinPolygonResponse => ", withinPolygonResponse);
                    if (withinPolygonResponse) {
                        let from = {'lat': data.lat, 'lng': data.lng};
                        let to = self.getLastImageLocation(data.index);

                        self.isUploadImageMinimumDistanceValid(from, to, self).then(function (validateResponse) {
                            //check if minimum distance between upload is valid
                            //validation after the first upload
                            console.log("validateResponse => ", validateResponse);
                            console.log("self.distanceFromLastImage => ", self.distanceFromLastImage);
                            if (validateResponse.isValid || data.index == 0) {

                                self.allMissionImages.splice(data.index, 1, data.image);
                                //notify server to update tske to done when user uploaded all images
                                data.totalImagesUploaded = self.allMissionImages.length;
                                data.totalImageRequested = Math.ceil(self.duration / self.activeMission.missionHoursRange);

                                //if its last image disable the interval of position watcher
                                if (data.totalImageRequested == data.totalImagesUploaded) {
                                    clearInterval(self.watchPositionInterval)
                                    window.localStorage.setItem('watchPositionActive' + self.$route.params.id, "0");
                                }
                                //check if image is
                                self.SAVE_IMAGE(data).then(function () {
                                    self.errorMessage = 'התמונה הועלתה בהצלחה';
                                    self.errorPopupVisible = true;
                                    self.popupReturnReload = true;
                                }).catch(function () {
                                    self.errorMessage = 'ישנה שגיאה בהעלאת התמונה,<br>נא לפנות לתמיכה';
                                    self.errorPopupVisible = true;
                                    self.popupReturnReload = false;
                                    let imageRef = 'imageCard' + (data.index + 1);
                                    self.$refs[imageRef][0].removeImage();
                                });
                            } else {
                                // self.REMOVE_LAST_IMAGE();
                                let imageRef = 'imageCard' + (data.index + 1);
                                self.$refs[imageRef][0].removeImage();
                                self.distanceBetweenImagesNotValidPopUp(validateResponse);
                            }
                        });

                    } else {
                        self.outsidePolygon();
                    }
                })
            } else {
                self.allMissionImages.splice(data.index, 1, data.image)
            }


        },
        hideErrorPopup: function () {
            this.errorMessage = '';
            this.errorPopupVisible = false;
            this.popupReturnReload = false;
        },
        successWatchLocation: function (pos) {
            this.currentLocation.lat = pos.coords.latitude;
            this.currentLocation.lng = pos.coords.longitude;
            this.calculateDistancFromSiteOrder();
            if (this.locationWatcher && this.$refs && this.$refs.mapComponent) {
                this.$refs.mapComponent.checkIfLocationWithinPolygon().then(function () {
                });
                this.locationWatcher = false
            } else {
                this.locationWatcher = true
            }
        },
        errorWatchLocation: function (err) {
            this.currentLocation.lat = '';
            this.currentLocation.lng = '';
            this.errorMessage = "שגיאה בקבלת המיקום";
            this.errorPopupVisible = true;
            this.popupReturnReload = false;
        },
        setCurrentPolygon: function (polygon) {
            this.currentPolygon = polygon;
        },
        openSubmissionPopupFunc: function (subMission) {
            this.activeSubMission = subMission;
            this.subMissionPopup = true
        },
        outsidePolygon: function () {
            this.errorMessage = 'ניתן לבצע פעולות רק בתוך <br> איזור הפעילות המוגדר במשימה';
            this.errorPopupVisible = true;
            this.popupReturnReload = false;
        },
        distanceBetweenImagesNotValidPopUp: function (validateResponse) {
            this.errorMessage = "<br>שים לב!";
            this.errorMessage += "<br>לא ניתן להעלות תמונה ,";
            this.errorMessage += "<br>מרחק מינימלי ביןו תמונה " + (validateResponse.missionMinimumDistanceFromImages * 1000).toString() + " מטרים.";
            this.errorMessage += "<br>הינך נמצא " + ((validateResponse.distanceFromLastImage * 1000).toFixed(2)).toString() + " מטרים מהתמונה האחרונה";

            this.errorPopupVisible = true;
            this.popupReturnReload = false;
        },
        formatDate: function (date) {
            return moment(date).format('DD/MM/YYYY')
        },
        toggleDescription: function () {
            this.IsShowDescription = !this.IsShowDescription;
        }

    },
    beforeMount() {
        let options = {};
        let self = this;
        this.GET_TASK_DATA(this.$route.params.id).then(function (reponse) {
            if (reponse) {
                options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };
            }
            self.getTime();
            self.IsLoading = false;
            if (self.activeMission && self.activeMission.trackingEnabled !== "סגור") {
                navigator.geolocation.watchPosition(self.successWatchLocation, self.errorWatchLocation, options);
            }
        }).catch(function (error) {
            self.IsLoading = false;

        });

    },
    mounted() {
        let self = this;
        setInterval(() => {
            self.getTime()
        }, 10000);
    },
    created() {
        // if(window.localStorage.getItem('watchPositionActive' + this.$route.params.id) == 1) {
        this.startWatchPosition();
        // }
    }
}
</script>

<style scoped lang="scss">
h1 {
    font-size: 22.5px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    line-height: 45px;
}

.v-sheet.headerBorder:not(.noBorder) {
    border-bottom: 10px solid #d8d8d8;
    box-sizing: content-box;
}

.circleTimeline {
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid #89bc41;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;
    top: 16px;

    &::before {
        content: '';
        position: relative;
        display: inline-block;
        background: #89bc41;
        width: 2px;
        height: 24px;
        right: calc(50% - 1px);
        top: 8px;

    }
}

.v-responsive.v-image {
    display: inline-block;
    position: relative;
    top: 10px;
    right: -5px;
    background: white;
}

span.textBorder {
    display: inline-block;
    width: 1px;
    background: black;
    height: 18px;
    position: relative;
    top: 4px;
    margin: 0 5px;
}

.v-btn {
    letter-spacing: 0;
    bottom: 0;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    z-index: 2;

    &.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $disabled_color !important;
        color: white !important;
    }

    &.v-btn--left {
        left: 0;
    }

    &.v-btn--right {
        right: 0;
    }
}


.missionText {
    white-space: nowrap;
}

/* תיאור המשימה */
/* קיים גם במסך הבית ובמסך משימה */
.missionDescription {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 68%;
    line-height: 0.7;
    display: inline-block;

    &.active {
        white-space: normal;
        text-overflow: initial;
        overflow: visible;
        display: inline;
    }

}


.doneMission.v-image {
    background: $primary_color;
}

.tasksWrapper {
    margin-bottom: 40px;
}

.missionCoinID {
    font-size: 22px;
}

.validationTime {
    display: flex;
    width: 100%;
    justify-content: center;
}

.timeLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        background: white;
        height: 33px;
        width: 50px;
        overflow: hidden;
        font-size: 19.2px;
        font-weight: bold;
        color: #89bc41;
        position: relative;

        &:before {
            background: $primary_color;
            width: 5px;
            height: 33px;
            content: '';
            position: absolute;
            right: calc(50% - 3px);
        }

        .numbersWrapper {
            flex-direction: column;
            text-align: right;
            display: inline-flex;
            width: 100%;
            transform: translateX(6px);
            letter-spacing: 13px;
            transition: 0.5s all;

            span {
                height: 33px;
                line-height: 33px;
                display: inline-flex;
            }
        }
    }
}

span.dotsWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 4px;
}

span.singleDot {
    display: inline-block;
    background: white;
    width: 5px;
    height: 5px;
    border-radius: 50px;
    margin: 3px 0;
}
</style>
