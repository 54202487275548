<template>
    <div class="home">
        <v-container>
            <v-row>
                <v-col class="mb-12 pb-0">
                    <p class="greetings text-center mb-0">שלום, {{userName}}</p>
                    <p class="text-center grey-lighten-3 py-5" v-if="missions.length === 0">העמוד בטעינה...</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="loaderSection" v-for="(index) in 4" v-if="missions.length === 0"
                         :key="index + 'skeleton'">
                        <v-skeleton-loader type="list-item-avatar@4">
                        </v-skeleton-loader>
                        <v-skeleton-loader type="article">
                        </v-skeleton-loader>
                    </div>
                    <v-mission-card v-for="(mission) in missions" :mission="mission" v-if="isTokenValid(mission)"
                                    :key="mission.missionID"></v-mission-card>
                    <p class="text-center" v-if="!missions">אין משימות לפינוי עבורך בשלב זה</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import vMissionCard from '../components/vMissionCard'
    import moment from 'moment'

    export default {
        name: 'vHome',
        data() {
            return {
                now: '',
                transition: 'scale-transition'
            }
        },
        components: {
            vMissionCard,
        },
        mounted(){
            this.getTime()
        },
        computed: {
            ...mapState(['userName', 'missions', 'showLoader'])

        },
        methods: {
            ...mapMutations(['GET_TASKS']),
            ...mapActions(['GET_TIME']),
            isTokenValid(mission) {
                let self = this;
                return mission.missionStatus !== 'בוצע' || moment(self.now, 'YYYY-MM-DDTHH:mm:ss.788Z').isBefore(moment(mission.missionCoinValidDate, moment.ISO_8601))
            },
            getTime: function () {
                let self = this;
                return self.GET_TIME().then(function (response) {
                    self.now = response;
                })
            },
        },
        created() {
            this.GET_TASKS()
        }
    }
</script>


<style lang="scss" scoped>
    .home {
        padding-top: 70px;
    }
</style>
