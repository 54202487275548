<template>
    <div>
        <v-container>
            <v-row>
                <v-col class="d-flex align-center flex-column">
                    <h1 class="mt-12 primary--text text-center">404</h1>
                    <p class="font-weight-bold">עמוד זה איננו קיים</p>
                    <v-btn :to="'/'" color="secondary" tile elevation="0" width="300" class="mt-9">חזרה לעמוד הבית</v-btn>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    export default {
        name: "v404"
    }
</script>

<style scoped lang="scss">
h1 {
    font-size: 150px;
}
</style>