<template>
    <div>
        <v-card class="mt-5 px-2 py-2">
            <div class="d-flex flex-no-wrap justify-space-between">
                <div class="hoursRange"
                     :class="{'notBetweenHours': !isBetweenHours, 'isAfterHours': isAfterHours,'imageUploaded': imageUploaded}">
                    {{startHour + ' - ' + endHour}}
                </div>
                <v-btn height="100" class="px-0" elevation="0" color="secondary" text :disabled="!isBetweenHours || receivedCode" @click="uploadImage">
                    <div class="addPhoto pt-5" :class="{'notBetweenHours': !isBetweenHours}" v-if="!imageUploaded">
                        <v-img max-width="36" max-height="33" :src="require(`@/assets/addPhoto.svg`)"></v-img>
                        <p class="addImageText">הוסף תמונה</p>
                    </div>
                    <div class="addedPhoto" v-else>
                        <v-img max-width="130" max-height="100" width="130" height="100" :src="imageUrl"></v-img>
                    </div>
                </v-btn>
                <v-file-input
                        :ref="'uploadImage' + index"
                        :id="'uploadImage' + index"
                        @change="handleImage"
                        class="imgToSend"
                        v-model="imgToSend"
                        accept="image/*"
                        prepend-icon="false"
                        capture="camera"
                        label="File input"></v-file-input>
            </div>
        </v-card>
    </div>
</template>

<script>
    import moment from 'moment'
    import {mapActions} from 'vuex';

    export default {
        name: "vImageCard",
        props: ['startHour', 'endHour', 'index','savedImage','receivedCode'],
        data: function () {
            return {
                isBetweenHours: false,
                isAfterHours: false,
                imageUploaded: false,
                imgToSend: null,
                imageName: '',
                imageUrl: '',
                imageFile: '',
                now: ''
            }
        },
        mounted() {

            let self = this;
            self.getTime();
            setInterval(function () {
                let now = moment(self.now, 'YYYY-MM-DDTHH:mm:ss.788Z').format('HH:mm');
                now = moment(now, 'HH:mm');
                let start = moment(self.startHour, 'HH:mm');
                let end = moment(self.endHour, 'HH:mm');
                self.isBetweenHours = moment(now).isBetween(start, end);
                self.isAfterHours = moment(now).isAfter(end);
            }, 1000)
            this.setPreloadedImage()
        },
        methods: {
            ...mapActions(['GET_TIME']),
            setPreloadedImage: function(){
                let self = this;
                if(!!this.savedImage) {
                    this.imageUrl = 'data:image/png;base64,' + this.savedImage.data;
                    this.imageUploaded = true;
                    let data = {
                        image: self.imageUrl,
                        index: self.index - 1,
                        savedImage: true
                    }
                    self.$emit('saveImageToMission', data)
                }
            },
            getTime: function () {
                let self = this;
                return self.GET_TIME().then(function (response) {
                    self.now = response;
                })
            },
            uploadImage: function () {
                let idToClick = 'uploadImage' + this.index
                let self = this;
                this.$parent.$refs.mapComponent.checkIfLocationWithinPolygon().then(function(response){
                        if(response) {
                            document.getElementById(idToClick).click()
                        } else {
                            self.$emit('outsidePolygon')
                        }
                })

            },
            removeImage: function (){
                this.imageName = '';
                this.imageFile = '';
                this.imageUrl = '';
                this.imageUploaded = false;
            },
            dataURLToBlob: function(dataURL) {
                let BASE64_MARKER = ';base64,';
                if (dataURL.indexOf(BASE64_MARKER) == -1) {
                    let parts = dataURL.split(',');
                    let contentType = parts[0].split(':')[1];
                    let raw = parts[1];

                    return new Blob([raw], {type: contentType});
                }

                let parts = dataURL.split(BASE64_MARKER);
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }

                return new Blob([uInt8Array], {type: contentType});
            },

            handleImage: function (e) {
                let self = this;
                this.$parent.$refs.mapComponent.checkIfLocationWithinPolygon().then(function(response){
                    if(response) {
                        const files = e;
                        if (files !== undefined) {
                            let reader = new FileReader();
                            reader.onload = function (readerEvent) {
                                let image = new Image();
                                image.onload = function (imageEvent) {
                                    // Resize the image
                                    let canvas = document.createElement('canvas'),
                                        max_size = 600,// TODO : pull max size from a site config
                                        width = image.width,
                                        height = image.height;
                                    if (width > height) {
                                        if (width > max_size) {
                                            height *= max_size / width;
                                            width = max_size;
                                        }
                                    } else {
                                        if (height > max_size) {
                                            width *= max_size / height;
                                            height = max_size;
                                        }
                                    }
                                    canvas.width = width;
                                    canvas.height = height;
                                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                                    let dataUrl = canvas.toDataURL('image/jpeg',.6);
                                    let resizedImage = self.dataURLToBlob(dataUrl);
                                    let file = new File([resizedImage], files.name, {type: "image/jpeg", lastModified: Date.now()});
                                    resizedImage.lastModifiedDate = new Date();
                                    resizedImage.name = files.name;
                                    self.imageName = files.name
                                    self.imageUrl = dataUrl;
                                    self.imageFile = dataUrl; // this is an image file that can be sent to server...
                                    self.imageUploaded = true;

                                    let data = {
                                        image: self.imageFile,
                                        index: self.index - 1
                                    }
                                    self.$emit('saveImageToMission', data)

                                }
                                image.src = readerEvent.target.result;
                            }
                            reader.readAsDataURL(files);
                        } else {
                            self.imageName = '';
                            self.imageFile = '';
                            self.imageUrl = '';
                            self.imageUploaded = false;
                        }
                    } else {
                        self.$emit('outsidePolygon')
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .addPhoto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: solid 2px $secondary_color;
        height: 100px;
        width: 130px;

        &.notBetweenHours {
            filter: grayscale(1);
            opacity: 0.6;
        }
    }

    button.v-btn.v-btn--disabled.theme--light:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        filter: grayscale(1);
        opacity: 1;
        background-color: transparent !important;
    }

    .hoursRange {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: $secondary_color;

        &.notBetweenHours {
            color: #afafaf;
        }
        &.imageUploaded {
            color: $primary_color;
        }
    }

    .addImageText {
        font-weight: bold;
        letter-spacing: -0.16px;
        text-align: right;
        color: $secondary_color;
    }

    .v-card {
        box-shadow: 0 0.5px 8.5px 1.5px #00000040;
        background-color: #ffffff;
        border-radius: 7px;
    }

    .v-input.imgToSend {
        display: none;
    }
</style>
