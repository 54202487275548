import axios from 'axios';
export const BaseUrl = (process.env.NODE_ENV === "development" ? "http://localhost:8000/index.php/" : "/");
import router from '../router';

export const HTTP = axios.create({
    baseURL: BaseUrl,
    withCredentials: true
});


HTTP.interceptors.request.use(function (config) {
    if(!window.sessionStorage.getItem('token') && router.currentRoute.path !== '/login') {
        router.push('/login')
    }
    config.headers.common['Authorization'] = "Bearer " + window.sessionStorage.getItem('token');
    return config;
});

HTTP.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    // Do something with response error
    // return error.response;
    return Promise.reject(error);
});
