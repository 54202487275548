<template>
    <div>
        <v-dialog
                width="375"
                v-model="showPopup"
                persistent
                max-width="375"
                :content-class="'errorPopupModal'"
        >
            <v-card class="pt-9">
                <v-card-text class="text-center font-weight-bold text--black px-0" v-html="errorMessage">

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="px-0 py-0 mt-5">
                    <v-btn
                            :width="'100%'"
                            height="54"
                            color="secondary"
                            tile
                            elevation="0"
                            @click="hideErrorPopup()"
                    >
                        חזרה להמשך המשימה
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: ['showPopup', 'errorMessage', 'needToReload'],
        name: "vStartMissionPopup",
        methods: {
            hideErrorPopup: function () {
                if (this.needToReload) {
                    window.location.reload()
                } else {
                    this.$emit('hideErrorPopup')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .errorPopupModal {
        .v-card__actions > .v-btn.v-btn + .v-btn {
            margin: 0;
        }

        .v-card__text {
            color: #000 !important;
            font-size: 19px;
            line-height: 1.21;
        }

        .v-card {
            border-radius: 7px;
            overflow: hidden;
        }

        .v-btn {
            font-size: 19px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0;
        }
    }
</style>
