import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader


Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
            light: {
                primary: '#89bc41',
                secondary: '#2891b5',
                accent: '#82B1FF',
                error: '#ff6060',
                info: '#2196F3',
                new: '#ff9500',
                success: '#4CAF50',
                warning: '#FFC107',
                decline: '#d8d8d8',
                done: '#afafaf',
            }
        }
    },
    icons: {
        iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});
