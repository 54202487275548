import Vue from 'vue'
import VueRouter from 'vue-router'
import vHome from '../views/vHome.vue'
import vLogin from '../views/vLogin.vue'
import vMissionPage from '../views/vMissionPage.vue'
import vLogout from '../views/vLogout.vue'
import v404 from '../views/v404.vue'

Vue.use(VueRouter)

const routes = [
    {path: '/', component: vHome},
    {path: '/login', component: vLogin},
    {path: '/mission/:id?', component: vMissionPage},
    {path: '/logout', component: vLogout},
    {path: '/404', component: v404},
    {path: "*", component: v404}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
