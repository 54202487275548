<template>
  <v-app>
    <v-content>
      <v-header v-if="$router.currentRoute.path !== '/login'"></v-header>
      <router-view></router-view>
    </v-content>
    <div class="loaderWrapper" v-if="showLoaderCircular">
      <v-progress-circular
              :size="70"
              :width="7"
              :color="'#89bc41'"
              indeterminate
      ></v-progress-circular>
    </div>
  </v-app>
</template>
<script>

  import vHeader from './components/vHeader'
  import {mapState} from 'vuex';

  export default {
  name: 'App',

  components: {
    vHeader
  },
  computed: {
    ...mapState(['showLoaderCircular']),
  },

  data: function (){
    return {
      currentPath: this.$router.currentRoute.path
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "assets/style.scss";
  .loaderWrapper {
    position: fixed;
    background: rgba(0, 0, 0, 0.46);
    width: 100vw;
    height: 100vh;
    z-index: 9;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
