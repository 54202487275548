<template>

    <v-card style="direction: rtl">
        <v-btn
            class="close_popup_btn"
            elevation="0"
            icon
            rounded
            small
            @click.prevent="closeModal()"
        >
            <v-icon size="20">fa-times</v-icon>
        </v-btn>
        <v-card-title class="headline pb-0 pt-10">{{ dataToUse.address }} {{ dataToUse.houseNumber }},
            {{ dataToUse.city }}
        </v-card-title>
        <v-card-subtitle class="ma-0 ">שם מלא:
            <span> {{ dataToUse.firstName }} {{ dataToUse.lastName }}</span>
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col cols="3">

                    <v-btn color="#79d1e9"
                           outlined
                           v-on="on"
                           rounded
                           fab
                           :depressed="true"
                           @click.prevent="goToWaze(dataToUse.Position_lat, dataToUse.Position_lng, dataToUse.address, dataToUse.houseNumber, dataToUse.city)">
                        <v-icon size="25">fab fa-waze</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="3">

                    <v-btn color="#25D366"
                           v-on="on"
                           outlined
                           fab
                           :depressed="true"
                           @click.prevent="goToWhatsapp(dataToUse.phone)"
                           rounded>
                        <v-icon size="25">fab fa-whatsapp</v-icon>
                    </v-btn>

                </v-col>
                <v-col cols="3">

                    <v-btn color="primary"
                           fab
                           outlined
                           :depressed="true"
                           @click="uploadImage"
                           v-on="on">
                        <v-icon v-if="!imageUploaded" size="25">fas fa-camera-retro</v-icon>
                        <v-icon v-else size="25">fas fa-exchange-alt</v-icon>
                    </v-btn>

                </v-col>
                <v-col cols="3">

                    <v-btn color="blue"
                           outlined
                           rounded
                           fab
                           :depressed="true"
                           v-on="on"
                           @click.prevent="goToPhone(dataToUse.phone)">
                        <v-icon size="25">fas fa-phone</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" style="display: none">
                    <v-file-input
                        :ref="'uploadImage'"
                        :id="'uploadImage'"
                        @change="handleImage"
                        class="imgToSend"
                        v-model="imgToSend"
                        accept="image/*"
                        :prepend-icon="''"
                        capture="camera"
                        label="העלאת תמונה"></v-file-input>
                </v-col>
                <v-col cols="12" v-if="imageUploaded">
                    <v-img
                        :max-width="'600px'"
                        :width="'100%'"
                        :src="imageUrl"></v-img>
                </v-col>
                <v-col cols="12" v-if="imageUploaded">
                    <v-btn color="error"
                           :large="true"
                           :depressed="true"
                           outlined
                           @click="removeImage"
                           :block="true">
                        <v-spacer></v-spacer>
                        הסרת תמונה
                        <v-spacer></v-spacer>
                        <v-icon :right="true">far fa-trash-alt</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-radio-group v-model="radios" :mandatory="true">
                        <v-radio label="הפינוי בוצע" value="בוצע"></v-radio>
                        <v-radio label="הפינוי לא בוצע" value="בוטל"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        outlined
                        name="input-7-4"
                        label="הערות"
                        value=""
                        rows="3"
                        no-resize
                        v-model="notes"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary"
                           :x-large="true"
                           :depressed="true"
                           class="sendButton"
                           @click.prevent="submitStatus(dataToUse)"
                           :block="true">
                        שלח
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-btn color="black"
                           class="white--text"
                           :depressed="true"
                           @click.prevent="closeModal()"
                           :x-large="true"
                           :block="true">
                        סגירה
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-overlay :value="innerOverlay" class="overlayLoader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            top
            :color="snackBarColor"
            class="text-center"
        >
            {{ snackBarText }}
        </v-snackbar>
    </v-card>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "vSubMissionPopup",
    components: {},
    props: ['dataToUse', 'popupShown'],
    data() {
        return {
            products: false,
            snackbar: false,
            innerOverlay: false,
            imgToSend: null,
            imageName: null,
            imageUrl: null,
            snackBarColor: '',
            snackBarText: '',
            imageFile: null,
            imageUploaded: false,
            bt_WrongAddress_State: false,
            signOptions: {
                dotSize: (0.5 + 2.5) / 2,
                minWidth: 0.5,
                maxWidth: 2.5,
                throttle: 16,
                minDistance: 5,
                backgroundColor: 'rgba(0,0,0,0)',
                penColor: 'black',
                velocityFilterWeight: 0.7,
                tag: process.env.VUE_APP_TAG_REPORT_CUSTOMER,
                onBegin: () => this.onBegin(),
                onEnd: () => this.onEnd()
            },
            radios: true,
            notes: '',
            tags: '',
        }
    },
    created() {

    },
    mounted() {
        console.log(this.dataToUse)
        this.reset();
    },
    watch: {
        display: function (value) {
            this.displayDialog = value;
            this.$nextTick(function () {
                this.imageUrl = null;
                this.$refs.signaturePad.resizeCanvas();
                this.$refs.signaturePad.fromDataURL(this.editedItem._signature);
            })
        }
    },
    computed: {},
    methods: {
        ...mapActions(['SUBMIT_SUB_TASK']),
        goToWhatsapp: function (phone) {
            let phoneToUse = phone.replace('0', '972')
            window.open('https://api.whatsapp.com/send?phone=' + phoneToUse + '&text=שלום, כאן תהיה הודעה לוואטסאפ')
        },
        goToPhone: function (phone) {
            // let phoneToUse = phone.replace('0', '972')
            window.open('tel:' + phone)
        },
        goToWaze: function (lat, lng, address, number, city) {
            debugger
            window.open('https://waze.com/ul?q=' + number + ' ' + address + ', ' + city + '&ll=' + lat + ',' + lng + '&navigate=yes')
        },
        reset() {
            this.innerOverlay = false;
            this.removeImage()
        },
        closeModal: function () {
            this.$emit('closeModal')
            this.reset()
        },
        submitStatus: function () {
            let self = this;
            if (this.radios === 'בוצע' && this.imageUrl === '') {
                this.snackbar = true;
                this.snackBarText = 'יש לצלם תמונה!';
                this.snackBarColor = 'error';
                return;
            }
            let data = {
                "note": this.notes,
                "image": this.imageUrl,
                "id": this.dataToUse.id,
                "lng": this.dataToUse.Position_lng,
                "lat": this.dataToUse.Position_lat,
                "status": this.radios,
                "address": this.dataToUse.address + ' ' + this.dataToUse.houseNumber + ', ' + this.dataToUse.city,
                "fullname": this.dataToUse.firstName + ' ' + this.dataToUse.lastName,
            }
            this.innerOverlay = true;
            self.$emit('showLoader');
            this.$store.dispatch('SUBMIT_SUB_TASK', data).then(function () {
                self.innerOverlay = true;
                self.closeModal()
                self.snackbar = true;
                self.snackBarText = 'הדיווח נשמר בהצלחה';
                self.snackBarColor = 'success';
                setTimeout(function(){
                    window.location.reload()
                },3000)
            }).catch(function () {
                self.innerOverlay = true;
                self.closeModal()
                self.snackbar = true;
                self.snackBarText = 'שגיאה בקבלת דיווח! יש לנסות שנית או לפנות לתמיכה';
                self.snackBarColor = 'error';
            })
        },
        handleImage: function (e) {
            let self = this;
            this.$parent.$parent.$parent.$refs.mapComponent.checkIfLocationWithinPolygon().then(function (response) {
                if (response) {
                    const files = e;
                    if (files !== undefined) {
                        let reader = new FileReader();
                        reader.onload = function (readerEvent) {
                            let image = new Image();
                            image.onload = function (imageEvent) {
                                // Resize the image
                                let canvas = document.createElement('canvas'),
                                    max_size = 600,// TODO : pull max size from a site config
                                    width = image.width,
                                    height = image.height;
                                if (width > height) {
                                    if (width > max_size) {
                                        height *= max_size / width;
                                        width = max_size;
                                    }
                                } else {
                                    if (height > max_size) {
                                        width *= max_size / height;
                                        height = max_size;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                                let dataUrl = canvas.toDataURL('image/jpeg', .6);
                                let resizedImage = self.dataURLToBlob(dataUrl);
                                let file = new File([resizedImage], files.name, {
                                    type: "image/jpeg",
                                    lastModified: Date.now()
                                });
                                resizedImage.lastModifiedDate = new Date();
                                resizedImage.name = files.name;

                                self.imageName = files.name
                                self.imageUrl = dataUrl;
                                self.imageFile = dataUrl; // this is an image file that can be sent to server...
                                self.imageUploaded = true;
                            }
                            image.src = readerEvent.target.result;
                        }
                        reader.readAsDataURL(files);
                    } else {
                        self.imageName = '';
                        self.imageFile = '';
                        self.imageUrl = '';
                        self.imageUploaded = false;
                    }
                } else {
                    self.$emit('outsidePolygon')
                }
            })
        },
        dataURLToBlob: function (dataURL) {
            let BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                let parts = dataURL.split(',');
                let contentType = parts[0].split(':')[1];
                let raw = parts[1];

                return new Blob([raw], {type: contentType});
            }

            let parts = dataURL.split(BASE64_MARKER);
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            let uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        },
        uploadImage: function () {
            document.getElementById('uploadImage').click();
        },
        removeImage: function () {
            this.imageName = '';
            this.imageFile = '';
            this.imageUrl = '';
            this.imageUploaded = false;
        }
    }
}
</script>

<style scoped lang="scss">
.imgToSend {
    display: none;
}

span.singleTag {
    padding: 5px 15px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 5px;
    border-radius: 50px;
    float: left;
}

button.close_popup_btn {
    position: absolute;
    z-index: 9999;
    background: white;
    top: 10px;
    left: 10px;
}
</style>
