<template>
    <div></div>
</template>

<script>
    export default {
        name: "vLogout",
        beforeMount(){
            window.localStorage.removeItem('userName');
            window.localStorage.removeItem('token');
            this.$router.push('login')
        }
    }
</script>

<style scoped>

</style>
