<template>
    <v-container
            class="loginBackground"
            heigth="100%"
            justify="center" align="center"
    >
        <v-row justify="center" align="center" heigth="100%">
            <v-col>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card min-height="395" width="'100%'">
                        <v-container>
                            <v-row justify="start" align="center">
                                <v-col cols="12" justify="center" align="center" class="pt-0 pb-0">
                                    <v-img width="200" :src="require('../assets/login-logo.png')" alt=""
                                           class="loginLogo"></v-img>
                                </v-col>
                                <v-col cols="12" justify="center" align="center" class="pt-0 pb-0">
                                    <h3>ברוכים הבאים
                                        <br>
                                        לאפליקציית המיחזור של מאי</h3>
                                </v-col>
                            </v-row>
                            <v-row justify="start" align="start">
                                <v-col cols="12" align-self="start" class="pt-0 pb-0 mt-0 px-6">
                                    <v-text-field
                                              class="pt-0 pb-0"
                                            :rules="[rules.nameRequired]"
                                            v-model="username" label="שם משתמש" required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" align-self="start" class="pt-0 pb-0 px-6">
                                    <v-text-field
                                            class="pt-0 pb-0"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            @click:append="show1 = !show1"
                                            :rules="[rules.passRequired]"
                                            v-model="password" label="סיסמה" required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" align-self="start" class="pt-0 pb-0 px-6">
                                    <p v-if="showErrorMessage" class="error--text">
                                        <v-icon color="error">error_outline</v-icon>
                                        {{errorMessage}}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-btn
                            class="doLogin"
                            color="secondary"
                            bottom
                            fixed
                            block
                            tile
                            right
                            large
                            height="60"
                            @click="doLogin"
                    >
                        כניסה
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';
    export default {
        name: "vLogin",
        data: () => {
            return {
                valid: true,
                show1: false,
                loginError: false,
                username: '',
                password: '',
                rules: {
                    nameRequired: value => !!value || 'שדה שם משתמש הינו שדה חובה.',
                    passRequired: value => !!value || 'שדה סיסמה הינו שדה חובה.',
                }
            }
        },
        computed: {

            ...mapState(['errorMessage','showErrorMessage'])
        },
        methods: {
            ...mapMutations(['DO_LOGIN']),
            doLogin: function() {
                if(this.username !== '' && this.password !== '') {
                    let data = {
                       user:  this.username,
                        password: this.password
                    }
                    this.DO_LOGIN(data)
                } else {
                    this.loginError = !this.loginError
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .loginBackground {
        height: 100vh;
        width: 100vw;
        background-image: url('../assets/bg-pic.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
    }

    .loginLogo {
        margin-bottom: 30px;
    }

    .doLogin {
        bottom: 0;
        right: 0;
        font-size: 19px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }

    h3 {
        font-size: 22px;
        line-height: 1.18;
        text-align: center;
        margin-bottom: 40px;
        color: #000000;
        font-weight: normal;
    }
</style>
