import Vue from 'vue'
import Vuex from 'vuex'
import {HTTP} from '../http/http-common';
import router from '../router';
import moment from 'moment';
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        errorMessage: 'שם משתמש או סיסמה שגויים',
        validDistanceFromDestination: 0.5, //in KM
        userName: window.sessionStorage.getItem('userName') ? window.sessionStorage.getItem('userName') : '',
        days: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
        missions: [],
        activeMission: null,
        state: null,
        showLoader: true,
        showLoaderCircular: false,
        receivedCode: false,
        showErrorMessage: false,
        overlay: false,
        duration: 0,
    },
    mutations: {
        /* get user tasks */
        GET_TASKS: (state) => {
            state.showLoader = true;
            HTTP.get('api/tasks').then((response) => {
                console.log(response);

                function isEmpty(obj) {
                    for (var prop in obj) {
                        if (obj.hasOwnProperty(prop))
                            return false;
                    }

                    return true;
                }

                if (!isEmpty(response)) {
                    state.missions = response
                    if (state.missions && state.missions.length) {
                        state.missions = state.missions.sort(function (a, b) {
                            if (moment(a.missionDate, 'yyyy-mm-dd').isBefore(moment(b.missionDate, 'yyyy-mm-dd'))) {
                                return -1
                            } else if (moment(b.missionDate, 'yyyy-mm-dd').isBefore(moment(a.missionDate, 'yyyy-mm-dd'))) {
                                return 1
                            } else {
                                if (parseInt(a.missionOrder) > parseInt(b.missionOrder)) {
                                    return -1
                                } else {
                                    return 1
                                }
                            }
                        });
                    }
                } else {
                    state.missions = false;
                }
                state.showLoader = false;
            }).catch((error) => {
                console.log(error);
                // router.push('/404')
            })
        },
        /* get task data */
        GET_TASK_DATA: (state, data) => {
            state.showLoader = true;
            state.activeMission = null;
            state.receivedCode = false;
            state.showLoader = false;
            //set response object to map mutation
            state.activeMission = data.activeMission;
            state.duration = data.duration;
        },
        /* save image to mission using index and base64 image*/
        REMOVE_LAST_IMAGE: (state, data) => {
            state.activeMission.missionPhotos.pop()
        },
        /* login to system*/
        DO_LOGIN: (state, data) => {
            state.showErrorMessage = false;
            HTTP.post('api/login', data).then((response) => {
                if (response && response.token) {
                    window.sessionStorage.setItem('token', response.token);
                    window.sessionStorage.setItem('userName', response.name);
                    state.userName = response.name
                    router.push('/')
                } else {
                    state.showErrorMessage = true;
                }
            }).catch((error) => {
                console.log('error ', error);
                // router.push('/404')
            })
        },
        /* get token code and token valid date*/
        GET_TOKEN_CODE: (state, data) => {
            state.overlay = false;
            state.activeMission.missionCoinID = data.missionCoinID;
            state.activeMission.missionCoinValidDate = data.missionCoinValidDate;
            state.receivedCode = data.receivedCode;
        },
        /* start working on mission*/
        SET_MISSION_AS_ACTIVE: (state, data) => {
            HTTP.put(`api/tasks/startMission/${data.id}`, data).then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                console.log(error);
                // router.push('/404')
            })
        },
        /* start working on mission*/
        CANCEL_MISSION: (state, data) => {
            HTTP.post(`api/tasks/cancelMission/${data.id}`, data).then((response) => {
                console.log(response);
                router.push('/')
            }).catch((error) => {
                console.log(error);
                // router.push('/404')
            })
        },
        /* show loader*/
        SHOW_LOADER: (state) => {
            state.showLoader = true;
        },
        /* show loader*/
        HIDE_LOADER: (state) => {
            state.showLoader = false;
        },
        /* show loader*/
        SHOW_OVERLAY: (state) => {
            state.overlay = true;
        },
        /* show loader*/
        HIDE_OVERLAY: (state) => {
            state.overlay = false;
        }
    },
    actions: {
        GET_TOKEN_CODE(context, data) {
            let resp_data = {};
            context.commit('SHOW_OVERLAY');
            return new Promise(function (resolve, reject) {
                HTTP.post(`api/tasks/finishMission/${data.id}`, data).then((response) => {
                    if (response && response.maiCoin) {
                        resp_data.missionCoinID = response.maiCoin;
                        resp_data.missionCoinValidDate = response.missionCoinValidDate + ' 23:55';
                        resp_data.receivedCode = true;
                    }
                    context.commit('GET_TOKEN_CODE', resp_data);

                    resolve(true);
                }).catch((error) => {

                    console.log(error);
                    data.overlay = false;
                    reject(false)
                })

            });
        },
        GET_TASK_DATA(context, id) {
            let data = {};
            return new Promise(function (resolve) {
                HTTP.get('api/tasks/' + id).then((response) => {
                    data.activeMission = response[id];
                    data.activeMission.missionId = id;
                    data.activeMission.missionDestinationCoords = data.activeMission.missionDestinationCoords[0];
                    let end = moment(data.activeMission.missionRangeEnd, 'HH:mm');
                    let start = moment(data.activeMission.missionRangeStart, 'HH:mm');
                    data.duration = end.diff(start, 'hours', true);
                    if (!data.activeMission.missionHoursRange) {
                        data.activeMission.missionHoursRange = 2 // hours range between each point
                    }
                    if (!data.activeMission.missionDistanceFromDestination) {
                        data.activeMission.missionDistanceFromDestination = 0.5 // range radius in KM
                    } else {
                        data.activeMission.missionDistanceFromDestination = data.activeMission.missionDistanceFromDestination / 1000;
                    }
                    data.showLoader = false;
                    data.receivedCode = !!data.activeMission.missionCoinID;

                    context.commit('GET_TASK_DATA', data)
                    resolve(true)
                }).catch((error) => {
                    console.log(error);
                    // router.push('/404')
                })

            })
        },
        SAVE_ROUTE: (context, data) => {
            return new Promise(function (resolve, reject) {
                HTTP.post('api/saveRoute', data).then(() => {
                    resolve(true)
                }).catch(() => {
                    reject(false);
                })
            })
        },
        /* save image to mission using index and base64 image*/
        SAVE_IMAGE: (context, data) => {
            context.state.showLoaderCircular = true;
            return new Promise(function (resolve, reject) {
                HTTP.post('api/uploadAttachment', data).then((response) => {
                    context.state.showLoaderCircular = false;
                    if (response) {
                        resolve(true)
                    } else {
                        reject(false)
                    }
                    resolve(true)
                }).catch((error) => {
                    context.state.showLoaderCircular = false;
                    reject(false);
                })
            })
        },
        /* save image to mission using index and base64 image*/
        GET_TIME: (context, data) => {
            return new Promise(function (resolve, reject) {
                axios.get('https://time.mly.co.il/').then((response) => {
                    if (response) {
                        resolve(response.data)
                    } else {
                        reject(false)
                    }
                    resolve(true)
                }).catch((error) => {
                    reject(false);
                })
            })
        },
        /* submit sub task from sub task popup*/
        SUBMIT_SUB_TASK: (context, data) => {
            return new Promise(function (resolve, reject) {
                HTTP.post('api/saveSubTask', data).then((response) => {
                    context.state.showLoaderCircular = false;
                    if (response) {
                        resolve(true)
                    } else {
                        reject(false)
                    }
                    resolve(true)
                }).catch((error) => {
                    context.state.showLoaderCircular = false;
                    reject(false);
                })
            })

        }
    },
    modules: {}
})
